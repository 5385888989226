import React, {Component} from 'react';
import { NavLink, Route } from "react-router-dom";
import HomeSlider from './HomeSlider.js';
import LocalSportsTVBanner from './LocalSportsTVBanner';
import APIService from "../../API";
import Helpers from "../Helpers";
import moment from 'moment';
import 'moment-timezone';
import { withRouter } from "react-router";


class Header extends Component {

  state = {
    latestSportsArticle: undefined,
    latestEventsArticle: undefined,
    latestNewsArticle: undefined,
    latestObituariesArticle: undefined,

    recentNewsArticles: [],
    recentSportsArticles: [],

    emergencyCounties: [],
    publicSitePreferences: [],
    closings: [],
    contests: [],
    activeElectionId: 0,
    electionCounties: [],
    now: new moment(),
  };

  componentDidMount() {
    this.fetchLatestArticle("News");
    this.fetchLatestArticle("Sports");
    this.fetchLatestArticle("Events");
    this.fetchLatestArticle("Obituaries");
    this.fetchRecentArticles('News');
    this.fetchRecentArticles('Sports');
    this.fetchPublicSitePreferences();
    this.fetchClosings();
    this.fetchContests();
    this.fetchActiveElection();
  }

  fetchLatestArticle = (type) => {
    fetch(APIService.serverUrl + "/api/public/article/latest", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        typeName: type,
      }),
    })
    .then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        let key = "latest" + type + "Article";
        this.setState({ [key] : json })
      } else {
        console.log("Fetch latest article type for " + type + " failed")
      }
    })
  };

  fetchRecentArticles = (type) => {
    fetch(APIService.serverUrl + "/api/public/article", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        typeName: type,
        limit: 3,
      }),
    })
      .then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        let key = 'recent'+type+'Articles'
        this.setState({ [key]: json, })
        // console.log("Fetch recent "+type+" articles Succeded")
        // console.log(json);
      } else {
        console.log("Fetch recent articles failed")
      }
    })
  }

  fetchPublicSitePreferences = () => {
    fetch(APIService.serverUrl + '/api/public/publicSitePreferences', {
      method: 'GET',
      headers: { "Content-Type": "application/json" },
    })
      .then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({ 'publicSitePreferences': json });
        // console.log('Get publicSitePreferences Succeded');
        if (json.stormMode) {
          this.fetchEmergencyCounties();
        }
      } else { console.log('Get publicSitePreferences Failed'); }
    })
  }

  fetchClosings = () => {
    fetch(APIService.serverUrl + '/api/public/closing', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({}),
    }).then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({ 'closings': json });
        // console.log('Get fetchClosings Succeded');
        // console.log('this.state.closings: ' + this.state.closings );
      } else { console.log('Get fetchClosings Failed'); }
    })
  }

  fetchEmergencyCounties = () => {
    fetch(APIService.serverUrl + '/api/public/snowEmergencyCounty', {
      method: 'GET',
      headers: { "Content-Type": "application/json" },
    })
      .then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({ 'emergencyCounties': json });
        // console.log('Get emergencyCounties Succeded');
      } else {
        console.log('Get Emergency List Failed');
      }
    })

  }

  fetchContests = () => {
    fetch(APIService.serverUrl + '/api/public/contest', {
      method: 'GET',
      headers: { "Content-Type": "application/json" },
    })
      .then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({ 'contests': json });
        // console.log('Get contests Succeded');
        // console.log(json);
      } else { console.log('Get publicSitePreferences Failed'); }
    })
  }

  fetchActiveElection = () => {
    fetch(APIService.serverUrl + '/api/public/election/active', {
      method: 'GET',
      headers: { "Content-Type": "application/json" },
    })
      .then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({ 'activeElectionId': json.id });
        this.fetchElectionCounties()
        // console.log('Get contests Succeded');
        // console.log(json);
      } else { console.log('Get publicSitePreferences Failed'); }
    })
  }

  fetchElectionCounties = () => {
    fetch(APIService.serverUrl + '/api/public/election-counties', {
      method: 'GET',
      headers: { "Content-Type": "application/json" },
    })
      .then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({ 'electionCounties': json });
      } else { console.log('Get publicSitePreferences Failed'); }
    })
  }

  renderGoogleAd2() {
    window.googletag.cmd.push(function() { window.googletag.display("div-gpt-ad-1530459263900-2") })
  }

  getOptimizedImage = (mainImage, size) => {
    let i = mainImage.optimizedImages.length;
    while (i--) {
      if (mainImage.optimizedImages[i].width === size) {
        return APIService.serverUrl+mainImage.optimizedImages[i].location;
      }
    }
  }


  render() {

    return (

      <div id="wrapper" className="wrapper">
        <div id="cp-header" className="cp-header">

          <div className="cp-topbar">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <ul className="toplinks">
                    <li><NavLink to="/">Home</NavLink></li>
                    <li><i className="fa fa-phone"/> + 419 529 NEWS</li>
                    <li><i className="fa fa-envelope-o"/><a href="mailto:newsroom@wmfd.com"> newsroom@wmfd.com</a></li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <div className="cp-toptools pull-right">
                    <ul>
                      <li><a href="https://secure.wmfd.com/store/"><i className="fa fa-cart-arrow-down"/></a></li>
                    </ul>
                  </div>
                  <div className="cp-topsocial pull-right">
                    <ul>
                      <li><a href="https://twitter.com/wmfdtv" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"/></a></li>&nbsp;
                      <li> <a href="https://www.facebook.com/wmfdtv" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"/></a></li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <LocalSportsTVBanner/>

          {/* ========== Begin Storm Mode School Closing Alerts ========== */}
          {/* START an IF clause for storm mode here and render below */}
          { this.state.publicSitePreferences.stormMode ?
            <div>
              <div className="cp-topbar">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <ul className="toplinks">
                        <li className="waves-effect waves-button">
                          <img border="0" src="/graphics/stormmode.jpg" height="15" alt='storm'/> Current County Snow Emergencies
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cp-topbar">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="toplinks">
                        {/* START a loop here to go through each county and its emergency level ***/}
                        {this.state.emergencyCounties.map( (county) => {
                          return(
                            <li className="waves-effect" key={county.id}>
                              <img
                                alt='wave'
                                border="0"
                                height="15"
                                src={"/graphics/"+county.snowEmergencyMode.imageName}
                              />{' ' + county.name}
                            </li>
                          )})}
                        {/* END a loop here to go through each county and its emergency level ***/}
                        <li className="waves-effect">
                          <a href="/emergency-levels"> Emergency Level Details</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : null }
      {/* END an IF clause here and render above */}
      {/* START an IF clause for closing here and render below */}
        { (this.state.publicSitePreferences.stormMode || this.state.closings.length) ?
          <div className="cp-topbar">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <ul className="toplinks">
                    <li><img border="0" src="/graphics/Alert.jpg" height="15" alt='alert' /> There are currently {this.state.closings.length} Closings and Delays Posted </li>
                    <li><a href="/closings">Details</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        : null }
      {/* END an IF clause for closing here and render below *** /}
      {/* ========== End Storm Mode School Closing Alerts ========== */}

          <div className="cp-logo-row">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="logo">
                    <a href="/"><img src="/images/logo.png" alt=""/></a>
                  </div>
                </div>
                <div className="col-md-8">

                  <div className="cp-advertisement">
                    <div className="widget advertisement">
                      <div className="ad-holder">
                        <div
                          id="div-gpt-ad-1530459263900-2"
                        >
                          { this.renderGoogleAd2() }

                          {/* <AD path="/20137842/DWMFD_728x90_ROS" /> */}

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="cp-megamenu">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="cp-mega-menu">
                    <label htmlFor="mobile-button"> <i className="fa fa-bars"/> </label>
                    <input id="mobile-button" type="checkbox"/>
                    <ul className="collapse main-menu">
                      <li className="slogo">{/* INDEX LINK */}
                        <a
                          style={{cursor:'pointer'}}
                          href={'/'}
                          ><img src="/images/logo-micon.png" alt=""/>
                        </a>
                      </li>
                      <li>{/* INDEX LINK */}
                        <a
                          style={{cursor:'pointer'}}
                          href={'/'}
                        >Home</a>
                      </li>
                      <li><a style={{cursor:'pointer'}} >News</a>
                        <ul className="drop-down full-width col-4 hover-expand">
                          <li>
                            <ul className="sub-menu">
                              <li>{/* NEWS LINK */}
                                <a
                                  style={{cursor:'pointer'}}
                                  href={Helpers.articleToURLPath(this.state.latestNewsArticle)}
                                >Local News</a>
                              </li>
                              <li>{/* ENTERTAINMENT LINK */}
                                <a
                                  href={Helpers.articleToURLPath(this.state.latestEventsArticle)}
                                >Events - Entertainment</a>
                              </li>
                              <li>{/* Obituaries LINK */}
                                <a
                                  style={{cursor:'pointer'}}
                                  href={Helpers.articleToURLPath(this.state.latestObituariesArticle)}
                                >Obituaries</a>
                              </li>
                              <li>{/* GAS PRICES LINK */}
                                <a
                                  style={{cursor:'pointer'}}
                                  href={'/gas'}
                                >Gas Prices</a>
                              </li>
                              <li>{/* CLOSINGS DELAYS LINK */}
                                <a
                                  href={'/closings'}
                                >Closings & Delays</a>
                              </li>
                            </ul>
                          </li>{/* START A MAP() FUNCTION HERE FOR RECENT NEWS ARTICLES */}
                          {this.state.recentNewsArticles.map(article => {
                            return (
                              <li key={article.id}>
                                <img
                                  src={Helpers.getOptimizedImage(article.mainImage, 263)}
                                  alt={article.headline}/>
                                <h3><a href={Helpers.articleToURLPath(article)}>{article.headline}</a></h3>
                              </li>
                            )
                          })}
                        </ul>
                      </li>

                      {!this.state.activeElectionId ? null :
                        <li style={{background: "#B40404"}}>
                          <a style={{cursor:'pointer', color: "white"}}>Election</a>
                          <ul className="drop-down one-column hover-fade">
                            <li>
                              <ul className="sub-menu">
                                <li >
                                  <a
                                    style={{cursor:'pointer'}}
                                    href={'/election/races'}
                                  >
                                    State Wide
                                  </a>
                                </li>
                                {this.state.electionCounties.map(county => {
                                  return (
                                    <li key={county.id} >
                                      <a
                                        style={{cursor:'pointer'}}
                                        href={'/election/races/' + county.id}
                                      >
                                        {county.name}
                                      </a>
                                    </li>
                                  )
                                })}
                              </ul>
                            </li>
                          </ul>
                        </li>
                      }

                      <li><a style={{cursor:'pointer'}} >CONTESTS</a>
                        <ul className="drop-down one-column hover-fade">
                          <li>
                            <ul className="sub-menu">
                              {this.state.contests.map(contest => {
                                return (
                                  // ((contest.startDate <= this.state.now) || (contest.endDate >= this.state.now)) ?
                                    contest.displayType.name === 'URL' ?
                                      <li key={contest.id} >
                                        <a
                                          style={{cursor:'pointer'}}
                                          href={contest.externalUrl}
                                          target="_blank" rel="noopener noreferrer"
                                        >
                                          {contest.image ?
                                            <img
                                              alt={contest.name}
                                              src={Helpers.getOptimizedImage(contest.image, 263)} />
                                            : ''}
                                          {contest.name}
                                        </a>
                                      </li>
                                    :
                                      <li key={contest.id} >
                                        <a
                                          style={{cursor:'pointer'}}
                                          href={'/contest/'+contest.id}
                                        >
                                          {contest.image ?
                                            <img
                                              alt={contest.name}
                                              src={Helpers.getOptimizedImage(contest.image, 263)} />
                                            : ''}
                                          {contest.name}
                                        </a>
                                      </li>

                                  // :
                                  //   ''
                                )
                              })}
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li><a style={{cursor:'pointer'}} >Sports</a>
                        <ul className="drop-down full-width col-4 hover-expand">
                          <li>
                            <ul className="sub-menu">
                              <li>{/* SPORTS LINK */}
                                <a
                                  style={{cursor:'pointer'}}
                                  href={Helpers.articleToURLPath(this.state.latestSportsArticle)}
                                >Local Sports Stories</a>
                              </li>
                              {/*<li>{/* YOUPICKIT LINK
                                <a
                                  style={{cursor:'pointer'}}
                                  href='https://www.wmfd.com/youpickit/default.asp'
                                  target="_blank" rel="noreferrer noopener"
                                >You Pick It Challenge</a>
                              </li>*/}

                              <li>
                                <a href="/scores">Scoreboards </a>
                              </li>
                              <li>
                                <a href="https://wmfd.com/article/mid-state-multimedia-sports-coverage-schedule---basketball-2019-2020-season/1266">Broadcast Schedule</a>
                              </li>
                              <li>
                                <a href="https://wmfdsports.com/">Watch Football</a>
                              </li>
                              <li>
                                <a href="https://wmfdsports.com/">Watch Soccer</a>
                              </li>
                              <li>
                                <a href="https://wmfdsports.com/">Watch Basketball</a>
                              </li>
                            </ul>
                          </li>{/* START A MAP() FUNCTION HERE FOR RECENT SPORTS STORIES */}
                          {this.state.recentSportsArticles.map(article => {
                            return (
                              <li key={article.id}>
                                <img
                                  src={Helpers.getOptimizedImage(article.mainImage, 263)}
                                  alt={article.headline}/>
                                <h3><a href={Helpers.articleToURLPath(article)}>{article.headline}</a></h3>
                              </li>
                            )
                          })}
                        </ul>{/* STOP A MAP() FUNCTION HERE FOR RECENT SPORTS STORIES */}
                      </li>
                      <li>{/* WEATHER LINK */}
                        <a
                          style={{cursor:'pointer'}}
                          href='/weather'
                        >Weather</a>
                      </li>
                      <li><a style={{cursor:'pointer'}} >Video & Audio</a>
                        <ul className="drop-down one-column hover-fade">
                          <li>
                            <ul>
                              <li className="validation"><h2>Watch WMFD Online</h2></li>
                              <li>
                                <a
                                  href="https://wmfdsports.com/player/27547/27547"
                                > WMFD TV LIVE (Stream)</a>
                              </li>
                              <li>
                                <a
                                  href="http://wmfdsports.com/"
                                > WMFD Sports - Football</a>
                              </li>
                              <li>
                                <a
                                  href="http://wmfdsports.com/"
                                > WMFD Sports - Soccer</a>
                              </li>
                              <li>
                                <a
                                  href="/focus-on-north-central-ohio"
                                >Focus on North Central Ohio</a>
                              </li>
                              <li>
                                <a
                                  href="/scores-and-more"
                                > Scores & More</a>
                              </li>
                              <li>
                                <a
                                  href="/sitting-down-with-kelby-king"
                                >Sittin Down With Kelby King</a>
                              </li>
                              <li>
                                <a
                                  href="/st-john"
                                >St.Johns United Chuch of Christ</a>
                              </li>
                              <li>
                                <a
                                  href="http://player.listenlive.co/62001" target="_blank" rel="noreferrer noopener"
                                >Mix106 WVNO Live</a>
                              </li>
                              <li>
                                <a
                                  href="/sparking-the-conversation"
                                >Sparking the Conversation</a>
                              </li>

                            </ul>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <a style={{cursor:'pointer'}} >Texting</a>
                        <ul className="drop-down full-width blog-menu hover-expand">
                          <li>
                            <ul>
                              <li>{/* TEXTING LINK */}
                                <a
                                  style={{cursor:'pointer'}}
                                  href='/text'
                                ><img src="/graphics/texting/text-closing-bell.jpg" alt="WMFD - Closing Bell To Your Cell" /></a>
                                <h3>{/* TEXTING LINK */}
                                  <a
                                    style={{cursor:'pointer'}}
                                    href='/text'
                                  >WMFD – Closing Bell To Your Cell</a>
                                </h3>
                              </li>
                              <li>{/* TEXTING LINK */}
                                <a
                                  style={{cursor:'pointer'}}
                                  href='/text'
                                ><img src="/graphics/texting/text-pump-patrol-kline.jpg" alt="Kline - Pump Patrol"/></a>
                                <h3>{/* TEXTING LINK */}
                                  <a
                                    style={{cursor:'pointer'}}
                                    href='/text'
                                  >Kline Home Exterior - Pump Patrol</a>
                                </h3>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <ul>
                              <li>{/* TEXTING LINK */}
                                <a
                                  style={{cursor:'pointer'}}
                                  href='/text'
                                ><img src="/graphics/texting/text-severe-weather-directions.jpg"
                                  alt="Directions - Severe Weather To Your Cell"/></a>
                                <h3>
                                  <a
                                    style={{cursor:'pointer'}}
                                    href='/text'
                                  >Severe Weather To Your Cell</a>
                                </h3>
                              </li>
                              <li>
                                <a
                                  style={{cursor:'pointer'}}
                                  href='/text'
                                ><img src="/graphics/texting/text-pollen-alerts-rphealth.jpg" alt=""/></a>
                                <h3>
                                  <a
                                    style={{cursor:'pointer'}}
                                    href='/text'
                                  >Richland Public Health - Pollen Alerts</a>
                                </h3>
                              </li>
                              <li>
                                <a
                                  style={{cursor:'pointer'}}
                                  href='/text'
                                ><img src="/graphics/texting/text-road-patrol-vans.jpg" alt=""/></a>
                                <h3>
                                  <a
                                    style={{cursor:'pointer'}}
                                    href='/text'
                                  >Road Patrol</a>
                                </h3>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <ul>
                              <li className="validation"><h2>Texting Categories</h2></li>
                              <li>
                                <a
                                  style={{cursor:'pointer'}}
                                  href='/text'
                                >Breaking News</a>
                              </li>
                              <li>
                                <a
                                  style={{cursor:'pointer'}}
                                  href='/text'
                                > Severe Weather</a>
                              </li>
                              <li>
                                <a
                                  style={{cursor:'pointer'}}
                                  href='/text'
                                > County Snow Emergencies</a>
                            </li>
                              <li>
                                <a
                                  style={{cursor:'pointer'}}
                                  href='/text'
                                > School Closings</a>
                            </li>
                              <li>
                                <a
                                  style={{cursor:'pointer'}}
                                  href='/text'
                                > Business Closings </a>
                            </li>
                              <li>
                                <a
                                  style={{cursor:'pointer'}}
                                  href='/text'
                                > Church Closings</a>
                            </li>
                            </ul>
                          </li>
                          <li>
                            <ul>
                              <li className="validation"></li>
                              <li>
                                <a
                                  style={{cursor:'pointer'}}
                                  href='/text'
                                > Pump Patrol</a>
                            </li>
                              <li>
                                <a
                                  style={{cursor:'pointer'}}
                                  href='/text'
                                > OSHP Road Patrol</a>
                            </li>
                              <li>
                                <a
                                  style={{cursor:'pointer'}}
                                  href='/text'
                                > Scores</a>
                            </li>
                              <li>
                                <a
                                  style={{cursor:'pointer'}}
                                  href='/text'
                                > Boil Alerts</a>
                            </li>
                              <li>
                                <a
                                  style={{cursor:'pointer'}}
                                  href='/text'
                                > NEW - Pollen Alerts</a>
                            </li>
                            </ul>
                          </li>
                        </ul>
                      </li>

                      <li><a href="https://secure.wmfd.com/store/">Shopping - Price Drops</a></li>
                      <li><a style={{cursor:'pointer'}} >About</a>
                        <div className="drop-down one-column hover-fade">
                          <ul>
                            <li><a href="https://www.tvpassport.com/tv-listings/stations/wmfd-central-ohio/2997" >WMFD Broadcast Schedule</a></li>
                            <li><a href="https://www.lighttv.com" >Light TV (WMFD.3) Broadcast Schedule</a></li>
                            <li><a href="https://publicfiles.fcc.gov/tv-profile/wmfd-tv">Childrens Programming</a></li>
                            <li><a href="/closed-captioning">Closed Captioning</a></li>
                            <li><a href="/eeo">EEO</a></li>
                            <li><a href="/privacy">Online Privacy Policy</a></li>
                            <li><a href="/publicfile-wmfd" >WMFD TV Public File</a></li>
                            {/*<li><a href="/publicfile-wohz" >WOHZ TV Public File</a></li>*/}
                            <li><a href="/publicfile-wvno">WVNO FM Public File</a></li>
                            <li><a href="/publicfile-wrgm" >WRGM AM Public File</a></li>
                            <li><a href="/contest-rules">Contest Rules</a></li>
                            <li><a href="/advertise">Advertise with us</a></li>
                          </ul>
                        </div>
                      </li>
                      <li><a href="/contact-us" style={{cursor:'pointer'}}>Contact Us</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Route exact path="/" render={ ()=> <HomeSlider /> } />

      </div>

    )
  }
}

export default withRouter(Header);
/*

*/
